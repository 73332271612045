import styled from "styled-components";

export const CarouselDiv = styled.div`
  padding-top: 1em;
  position: relative;
  height: 150px;
  max-width: 630px;
  margin-bottom: 60px;
  @media only screen and (max-width: 992px) {
    height: 100px;
    max-width: 300px;
    margin: auto;
  }

  .basedOn {
    text-align: center;
    font-size: 1.2em;
    color: #fff;
    a,
    a:visited,
    a:hover,
    a:active {
      color: #ffeb3b;
    }
  }

  .item {
    text-align: center;
    color: white;
    font-size: 40px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: box-shadow 1s, height 250ms, width 250ms, left 1s, margin-top 1s,
      line-height 250ms, background-color 1s;
  }

  h1 {
    display: none;
  }

  .level-3 {
    left: 4%;
  }

  .level-2 {
    left: 15%;
  }

  .level-1 {
    left: 30%;
  }

  .level0 {
    left: 50%;
    top: 100px;
    height: 165px;
    .user-info {
      display: block;
      color: #fff;
      @media only screen and (min-width: 992px) {
        width: 600px;
      }
    }
    @media only screen and (max-width: 992px) {
      top: 50px;
      height: 60px;
    }
  }

  .level1 {
    left: 70%;
  }

  .level2 {
    left: 85%;
  }

  .level3 {
    left: 96%;
  }

  .level3,
  .level-3 {
    img {
      opacity: 0.15;
    }
  }

  .level-2,
  .level2 {
    z-index: 0;
    opacity: 0.2;
    img {
      width: 30px;
      height: 30px;
    }
    @media only screen and (min-width: 992px) {
      img {
        width: 65px;
        height: 65px;
      }
    }
  }

  .level-1,
  .level1 {
    z-index: 1;
    opacity: 0.4;
    img {
      width: 40px;
      height: 40px;
    }
    @media only screen and (min-width: 992px) {
      img {
        width: 90px;
        height: 90px;
      }
    }
  }

  .level0 {
    z-index: 2;
    img {
      width: 60px;
      height: 60px;
    }
    @media only screen and (min-width: 992px) {
      img {
        width: 120px;
        height: 120px;
      }
    }
  }

  .left-enter {
    opacity: 0;
    left: 85%;
    height: 72% - 80%;
    width: 17% - 25%;
    line-height: 72% - 30%;

    &.left-enter-active {
      opacity: 1;
      left: 85%;
      height: 72%;
      width: 17%;
      line-height: 72%;
      transition: box-shadow 1s, left 1s, opacity 1s, height 250ms, width 250ms,
        margin-top 1s, line-height 1s;
    }
  }

  .left-exit {
    opacity: 1;
    left: 15%;
    height: 72%;
    width: 17%;
    line-height: 72%;
    z-index: -1;

    &.left-exit-active {
      z-index: -1;
      left: 15%;
      opacity: 0;
      height: 72% - 80%;
      width: 17% - 25%;
      line-height: 120px;
      transition: box-shadow 1s, left 1s, opacity 1s, height 250ms, width 250ms,
        margin-top 1s, line-height 1s;
    }
  }

  .right-enter {
    opacity: 0;
    left: 15%;
    height: 72% - 80%;
    width: 17% - 25%;
    line-height: 72% - 30%;

    &.right-enter-active {
      left: 15%;
      opacity: 1;
      height: 72%;
      line-height: 72%;
      width: 17%;
      transition: box-shadow 1s, left 1s, opacity 1s, height 250ms, width 250ms,
        margin-top 1s, line-height 1s;
    }
  }

  .right-exit {
    left: 85%;
    height: 72%;
    opacity: 1;
    line-height: 72%;
    width: 17%;

    &.right-exit-active {
      left: 85%;
      opacity: 0;
      height: 72% - 80%;
      width: 17% - 25%;
      line-height: 72% - 30%;
      transition: box-shadow 1s, left 1s, opacity 1s, height 250ms, width 250ms,
        margin-top 1s, line-height 1s;
    }
  }

  .noselect {
    -webkit-user-select: none;
    -html-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
`;
