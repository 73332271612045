import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Image, Form } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import AddressForm from './components/AddressForm';
import ProfileForm from './components/ProfileForm';
import ContactForm from './components/ContactForm';
import DescriptionForm from './components/DescriptionForm';
import ExperienceForm from './components/ExperienceForm';
import InterestForm from './components/InterestForm';
import BottomBar from '../../components/BottomBar';
import Navigation from '../../components/TopBar';
import PreferenceForm from './components/PreferenceForm';
import Footer from '../../components/Footer';
import UploadImage from './components/UploadImage';
import RequestModal from './components/RequestModal';
import MobileTopBar from '../../components/MobileTopBar';
import RoleForm from './components/RoleForm';
import { useStore } from '../../store';
import { ProfileDiv } from './elements/styles';
import UserHolder from '../../assets/images/profile-holder.svg';
import edit from '../../assets/icons/edit.svg';
import smWave from '../../assets/images/g10.svg';
import wave from '../../assets/images/wave1.svg';
import { useHistory } from 'react-router';
import down from '../../assets/icons/dropdown-arrow.svg';
import up from '../../assets/icons/dropup-arrow.svg';

const Profile = observer(() => {
  const { accountStore, searchStore } = useStore();
  const { user, roleList } = accountStore;
  const {
    cities,
    listCities,
    listStates,
    interests,
    engagements,
    listEngagements,
    searchinRoles,
  } = searchStore;

  useEffect(() => {
    listCities();
  }, [listCities]);

  useEffect(() => {
    listStates();
  }, [listStates]);

  useEffect(() => {
    listEngagements();
  }, [listEngagements]);

  const [editDescription, seteditDescription] = useState(false);
  const [editAddress, seteditAddress] = useState(false);
  const [editPersonalAddress, seteditPersonalAddress] = useState(false);
  const [editProfileInfo, seteditProfileInfo] = useState(false);
  const [editContactInfo, seteditContactInfo] = useState(false);
  const [editProfilePic, seteditProfilePic] = useState(false);
  const [editExperience, seteditExperience] = useState(false);
  const [editInterest, seteditInterest] = useState(false);
  const [editRequest, setrequestEdit] = useState(false);
  const [editRole, setEditRole] = useState(false);
  const [editEngagement, seteditEngagement] = useState(false);
  const [more, setMore] = useState(false);

  const getAddress = (item) => {
    return [item?.street, item?.city, item?.pincode]
      .filter(Boolean)
      .join(', ')
      .toLowerCase();
  };
  const location = useHistory();

  function toggle() {
    setMore(!more);
  }

  return (
    <>
      <Helmet>
        <title>Edit Profile | 121connects</title>
      </Helmet>

      <MobileTopBar />
      <Navigation />

      <ProfileDiv>
        <div className='glass'>
          <div className='glass-design d-none  d-lg-block'>
            <Image
              src={wave}
              className='d-none  d-lg-block sm-wave'
              id='header-img'
            />
          </div>

          <div className='profile-data'>
            <div className='col-lg-8'>
              <div className='profile-info'>
                <div className='profile-pic'>
                  <Image
                    src={user?.photo ? user.photo : UserHolder}
                    alt='profile-pic'
                    className='profile-image'
                    roundedCircle
                  />

                  <button
                    className='edit-pic'
                    onClick={() => seteditProfilePic(true)}
                  >
                    <Image src={edit} />
                  </button>

                  <Image src={smWave} className='sm-wave d-lg-none' />

                  <UploadImage
                    user={user}
                    show={editProfilePic}
                    setEdit={seteditProfilePic}
                  />
                </div>

                <div className='info-tab'>
                  <ProfileForm
                    show={editProfileInfo}
                    setEdit={seteditProfileInfo}
                    user={user}
                  />
                  <div className='d-flex justify-content-between'>
                    <h1 className='page-title'>
                      {user?.first_name} {user?.last_name}
                    </h1>
                  </div>
                  <div className='primary'>
                    <h6>{user?.title} </h6>
                    <h6> {user?.company?.name}</h6>
                    <div className='d-flex justify-content-between'>
                      <button
                        onClick={() => seteditProfileInfo(true)}
                        className='edit-button'
                        id='header-edit'
                      >
                        Edit
                      </button>
                      <button
                        className='edit-button'
                        onClick={() =>
                          location.push(
                            `/${user?.first_name
                              ?.toLowerCase()
                              .replace(' ', '-')}-${user?.last_name
                              ?.toLowerCase()
                              .replace(' ', '-')}_${user?.id}_m`
                          )
                        }
                      >
                        View public profile
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <ContactForm
                show={editContactInfo}
                setEdit={seteditContactInfo}
                user={user}
              />
              <RequestModal
                show={editRequest}
                setEdit={setrequestEdit}
                user={user}
              />
              <div className='d-block d-lg-flex top-divs'>
                <div className='profile-bio col'>
                  <div className='d-flex justify-content-between '>
                    <h2 className='Pri-contact'>Primary contact</h2>
                    <button
                      onClick={() => setrequestEdit(true)}
                      className='edit-button'
                    >
                      Request edit
                    </button>
                  </div>
                  <div className='d-flex justify-content-between'>
                    <h6>{user?.email}</h6>
                  </div>
                  <h6 className='phone'>{user?.mobile}</h6>
                </div>
                {user.user_type === 0 ? (
                  <div className='profile-bio col secondary '>
                    <div className='d-flex justify-content-between'>
                      <h2>Secondary contact</h2>
                      <button
                        onClick={() => seteditContactInfo(true)}
                        className='edit-button'
                      >
                        Edit
                      </button>
                    </div>
                    <div>
                      <h6 className='d-flex'>{user?.secondary_email}</h6>
                      <h6 className='phone d-flex'>{user?.alternate_mobile}</h6>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
              {user?.user_type === 0 ? (
                <div className='profile-bio'>
                  <div>
                    <div className='d-flex justify-content-between'>
                      <h2>Engagement</h2>
                      <button
                        className='edit-button'
                        onClick={() => seteditEngagement(true)}
                      >
                        <p>Edit</p>
                      </button>
                    </div>

                    <div className='bio-details'>
                      <div className='d-flex justify-content-between'>
                        <PreferenceForm
                          show={editEngagement}
                          setEdit={seteditEngagement}
                          user={user}
                          engagements={engagements}
                        />
                      </div>
                    </div>
                    <div className='engage-div'>
                      {engagements?.map((item) => (
                        <Form.Check
                          key={item.name}
                          label={item.name}
                          value={item.name}
                          checked={user.engagements
                            ?.map((element) => element.id)
                            .includes(item.id)}
                          disabled
                        />
                      ))}
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}

              {user?.user_type === 0 ? (
                <div className='profile-bio'>
                  <div className='d-flex justify-content-between'>
                    <h2>Areas of interest</h2>
                    <button
                      className='edit-button'
                      onClick={() => seteditInterest(true)}
                    >
                      <p>Edit</p>
                    </button>
                  </div>
                  <InterestForm
                    show={editInterest}
                    setEdit={seteditInterest}
                    interests={interests}
                    user={user}
                  />

                  <div className='engage-div'>
                    {interests.map((item) => {
                      return (
                        <Form.Check
                          key={item.name}
                          label={item.name}
                          value={item.name}
                          checked={user?.interests
                            ?.map((element) => element.id)
                            .includes(item.id)}
                          disabled
                        />
                      );
                    })}
                  </div>
                </div>
              ) : (
                ''
              )}

              {user?.user_type === 0 ? (
                <div className='profile-bio'>
                  <RoleForm show={editRole} setEdit={setEditRole} />

                  <div className='d-flex justify-content-between'>
                    <h2>Responsibilities</h2>
                    <button
                      onClick={() => setEditRole(true)}
                      className='edit-button'
                    >
                      Edit
                    </button>
                  </div>
                  <div className='engage-div'>
                    {searchinRoles.map((item) => {
                      return (
                        <Form.Check
                          key={item.rolel2}
                          label={item.rolel2}
                          value={item.id}
                          checked={roleList.includes(item.id)}
                          disabled
                        />
                      );
                    })}{' '}
                  </div>
                </div>
              ) : (
                ''
              )}

              {/*{user?.user_type === 0 && (
                <div className="profile-bio d-lg-none">
                  <div className="d-flex justify-content-between">
                    <h5>Meeting preference</h5>
                  </div>

                  <div className="bio-details">
                    <PreferenceForm user={user} engagements={engagements} />
                  </div>
                </div>
              )}*/}

              <div className='profile-bio'>
                <DescriptionForm
                  show={editDescription}
                  user={user}
                  setEdit={seteditDescription}
                />
                <div className='d-flex justify-content-between'>
                  <h2> About me</h2>
                  <button
                    onClick={() => seteditDescription(true)}
                    className='edit-button'
                  >
                    <p>Edit</p>
                  </button>
                </div>

                <div className='bio-details'>
                  <p
                    className={classNames({
                      less: !more,
                    })}
                  >
                    {user?.description}
                  </p>
                  {user?.description && (
                    <div className='readmore'>
                      {!more ? (
                        <div className='p-moreless' onClick={() => toggle()}>
                          More <img className='more-less' src={down} alt='' />
                        </div>
                      ) : (
                        <div className='p-moreless' onClick={() => toggle()}>
                          Less <img className='more-less' src={up} alt='' />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              {user?.user_type === 0 ? (
                <div className='profile-bio'>
                  <ExperienceForm
                    show={editExperience}
                    user={user}
                    setEdit={seteditExperience}
                  />
                  <div className='d-flex justify-content-between'>
                    <h2>Experience</h2>
                    <button
                      className='edit-button'
                      onClick={() => seteditExperience(true)}
                    >
                      <p>Edit</p>
                    </button>
                  </div>

                  <div className='bio-details'>
                    <p>{user?.experience}</p>
                  </div>

                  {/* <div className="d-flex tags">
                    {user?.interests?.map((item) => (
                      <div className="interest" key={item.id}>
                        {item.name}
                      </div>
                    ))}
                  </div> */}
                </div>
              ) : (
                ''
              )}
            </div>
            <div className='col-lg-4 address-div'>
              {user.user_type === 0 ? (
                <div className='profile-bio'>
                  <AddressForm
                    address={user?.address_set?.find(
                      (item) => item.address_type === 0
                    )}
                    show={editAddress}
                    setEdit={seteditAddress}
                    cities={cities}
                    address_type={0}
                  />
                  <div className='d-flex justify-content-between'>
                    <h2>Office Address</h2>

                    <button
                      onClick={() => seteditAddress(true)}
                      className='edit-button'
                    >
                      <p>Edit</p>
                    </button>
                  </div>

                  <h4 className='side-div-top'>
                    {getAddress(
                      user?.address_set?.find(
                        (address) => address.address_type === 0
                      )
                    )}
                  </h4>
                </div>
              ) : (
                ''
              )}

              {user.user_type === 0 ? (
                <div className='profile-bio'>
                  <AddressForm
                    show={editPersonalAddress}
                    address={user?.address_set?.find(
                      (item) => item.address_type === 1
                    )}
                    setEdit={seteditPersonalAddress}
                    cities={cities}
                    address_type={1}
                  />
                  <div className='d-flex justify-content-between'>
                    <h2>Personal Address</h2>
                    <button
                      onClick={() => seteditPersonalAddress(true)}
                      className='edit-button'
                    >
                      <p>Edit</p>
                    </button>
                  </div>
                  <h4>
                    {getAddress(
                      user?.address_set?.find(
                        (address) => address.address_type === 1
                      )
                    )}
                  </h4>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </ProfileDiv>
      <div className='footbar'>
        <Footer />
      </div>
      <BottomBar />
    </>
  );
});

export default Profile;
