import React from "react";
import { observer } from "mobx-react-lite";
import { useForm } from "react-hook-form";
import { Form, Button, Image } from "react-bootstrap";
import { useStore } from "../../../store";
import { ProfileFormDiv } from "../elements/styles";
import close from "../../../assets/icons/close.svg";

const ExperienceForm = observer(({ user, show, setEdit }) => {
  const { authStore } = useStore();

  const { onEditProfile } = authStore;

  const { handleSubmit, register,reset } = useForm();

  const onSubmit = (data) => {
    onEditProfile(data);
    setEdit(false);
  };

  return (
    <ProfileFormDiv show={show}>
      <div className="d-flex justify-content-between align-items-start mb-4">
        <div>
          <h4>Edit your profile</h4>
          <h2>Edit experience</h2>
        </div>

        <button onClick={() => {setEdit(false); reset()}} className="close-button">
          <Image src={close} alt="close" className="close" />
        </button>
      </div>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group>
          <Form.Control
            size="sm"
            as="textarea"
            name="experience"
            className="description"
            rows="10"
            defaultValue={user?.experience}
            {...register("experience")}
          />
        </Form.Group>

        <Button className="btn-default" type="submit">
          Save
        </Button>
      </Form>
    </ProfileFormDiv>
  );
});

export default ExperienceForm;
