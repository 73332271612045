import styled from 'styled-components';
// import logo from "../../assets/images/Background2.svg";

export const NavBar = styled.div`
  .tabs {
    padding: 21px 11px 20px 11px;
    background: #fff;
  }
  .top-content {
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    .form-control {
      width: 415px;
    }
    .form-control:focus {
      box-shadow: none;
    }
  }

  .seeAll {
    color: #00aeae;
    cursor: pointer;
    font-size: 12px;
  }

  .nav-link {
    color: #8c97ac;
  }
  .active {
    color: #00aeae;
  }
  .userpic {
    height: 38px;
    width: 38px;
    border-radius: 50%;
    font-weight: 600;
    color: black;
    text-transform: uppercase;
  }

  .profile-pic {
    padding: 0;
    border-radius: 50%;
    border: #fff 3px solid;
    background: #fff;

    img {
      width: 34px;
      height: 34px;
      box-shadow: 0px 0px 25px 0px #0e7d584d;
    }
  }

  .dropdown {
    margin-left: 20px;
  }

  .dropdown-toggle {
    color: #fff;
    padding-top: 7px;
  }
  .dropdown-menu {
    padding: 40px 16px 24px 43px;
    width: 350px;
    background: #fff;
    border: 0.5px solid #cdeaf0;
    box-shadow: 3px 4px 20px 0px #0000001a;
    top: 5px !important;

    .nav-link {
      color: #111;
    }

    .menu-arrow {
      position: absolute;
      top: -5px;
      right: 15px;
      border: 0.5px solid #cdeaf0;
      box-shadow: 3px 4px 20px 0px #0000001a;
      background: #fff;
      border-width: 0 0.5px 0.5px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
    }

    h4 {
      font-size: 18px;
      color: #333333;
      font-weight: 600;
    }

    .designation {
      font-size: 12px;
      color: #666666;
      line-height: 16px;
    }

    .points {
      color: #666666;
      font-weight: 600;
      font-size: 10px;
      padding-left: 10px;
      h5 {
        font-size: 10px;
        margin-bottom: 0;
        margin-left: 4px;
        color: #333333;
      }
      img {
        margin-right: 7px;
        margin-bottom: 0;
      }
    }

    .btn-default {
      padding: 10px 34px;
      font-size: 14px;
      width: 145px;
      margin-top: 17px;
    }

    .btn-actions {
      color: #00aeae;
      font-size: 16px;
      flex-direction: column;
      align-items: flex-start;
      height: 90px;
      justify-content: space-evenly;
      // border-top: 1px solid #7e7e7e33;
      button {
        padding: 0;
        background: 0;
        border: 0;
        font-weight: 700;
        color: #00aeae;
        font-size: 12px;
      }
    }
    .top {
      margin-top: 27px;
      // height: 143px;
    }
  }

  .consent {
    color: #666666;
    font-size: 11px;
    margin-top: 27px;
    button {
      text-decoration: underline;
      padding: 0;
      background: 0;
      border: 0;
      font-weight: 700;
      color: #00aeae;
    }
  }
  .a_createProject {
    width: 142px;
    height: 40px;
    padding: 0;
    font-size: 14px;
    margin-right: 20px;
  }
  .a_selectSidebar {
    padding: 0;

    :after {
      display: none;
    }
  }

  .cart-icon {
    height: 34px;
  }

  .cart-div {
    position: relative;
    .alert {
      position: absolute;
      min-width: max-content;
      padding: 15px 15px 10px 11px;
      bottom: -110px;
      z-index: 100;
      right: -50px;
      background: #fff7da;
      border-radius: 5px;
      color: #666666;
      font-size: 10px;

      span {
        font-weight: 700;
        padding: 0 2px;
      }

      .alert-arrow {
        position: absolute;
        top: -5px;
        right: 60px;
        border: solid #fff7da;
        background: #fff7da;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
      }

      .success-circle {
        width: 11px;
        height: 11px;
        border-radius: 50%;
        background: #27d3db;
        margin-right: 2px;
        #checkCircle {
          height: 3.5px;
          width: 5.5px;
          margin-left: 3px;
          margin-bottom: 5px;
        }
      }
    }
  }

  .chips {
    max-width: 415px;
  }

  .form-control {
    border: 1px solid #00aeae;
    background: #ebf4f9;
    width: 419px;
  }

  .cart {
    border-radius: 50%;
    border: 0;
    background: #fff;
    width: 40px;
    height: 40px;
    position: relative;
    margin-left: 4px;

    .cart-count {
      right: -16px;
      top: 5px;
      position: absolute;
      color: #ff647f;
      width: 22px;
      font-size: 12px;
      height: 18px;
      font-weight: 700;
      background: #fff;
      border: 0.5px solid #00aeae;
      border-radius: 5px;
    }
  }

  .search-icon {
    margin-top: -26px;
  }

  .search {
    position: relative;

    .search-initiator {
      position: absolute;
      font-size: 14px;
      color: #666666;
      margin-top: -15px;
      .dropdown {
        margin-left: 20px;
      }

      .droparrow {
        margin: 0 0 2px 5px;
        border-left: 1px solid #00aeae;
        border-top: 1px solid #00aeae;
        padding: 3.5px;
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
      }

      .arrow {
        position: absolute;
        top: -5px;
        left: 180px;
        border: solid #fff;
        background: #fff;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
      }

      .dropdown-toggle {
        color: #00aeae;
        font-weight: 600;
        font-size: 14px;
        box-shadow: none;
      }

      .dropdown-item {
        color: #00aeae;
        font-size: 12px;
      }
      .dropdown-menu {
        padding: 40px 16px 24px 43px;
        width: fit-content;
        background: #fff;
        top: 20px !important;
        left: -150px !important;
        .nav-link {
          color: #111;
        }
      }
    }
  }
`;
